import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit{

  @Input("role_type") role_type: string;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    // console.log('in')
    this.elementRef.nativeElement.style.display = "none";
    this.checkAccess();
  }

  checkAccess() {
    // console.log(this.role_type)
    this.elementRef.nativeElement.style.display = this.role_type ? "block" : "none";
  }

}
