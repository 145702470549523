import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";

export function initializer(keycloak: KeycloakService): () => Promise<any> {

    return (): Promise<any> => {
        return new Promise<void>(async (resolve, reject) => {
          try {
            await keycloak.init({
                config: {
                    url: environment.keycloak.issuer,
                    realm: environment.keycloak.realm,
                    clientId: environment.keycloak.clientId,
                    credentials: {
                      secret: environment.keycloak.secrect
                    }
                },
              loadUserProfileAtStartUp: false,
              initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false
              },
              bearerExcludedUrls: []
            });
            console.log("Caught");
            try {
                console.log(keycloak.getKeycloakInstance().token);
                sessionStorage.setItem("accessToken", keycloak.getKeycloakInstance().token);
            } catch (error) {
                console.log("asd");
            }

            resolve();
          } catch (error) {
            reject(error);
          }
        });
      };
}
