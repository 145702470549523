import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './_core/services/interceptor/http-request.interceptor';
import { NgbToastModule } from 'ngb-toast';
import { LoadingInterceptor } from './_core/services/interceptor/loading.interceptor.service';
import { DatePipe } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { initializer } from './_core/utils/keycloak-init'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AccessControlDirective } from './_core/directives/access-control.directive';

@NgModule({
  declarations: [
    AppComponent,
    AccessControlDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbToastModule,
    MaterialModule,
    KeycloakAngularModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
