export const environment = {

  production: false,

  jobServiceS3Bucket: {
    accessKeyId: 'AKIA2MQRDEC2M5R25UGA',
    secretAccessKey: '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',
    region: 'us-east-1',

    buckets: {
      bucketPath: 'horoscope-bkt',
      serviceProviderFolder: '/service-provider-images',
    },

    cloudFront: {
      serviceProvider: 'https://d1muc0vwhaberz.cloudfront.net/service-provider-images/'
    }
  },

  jobServiceApi: 'http://3.91.62.59:8080/',
  voteServiceApi: 'https://voting-api.rumex.lk/',


  dflixApi: 'https://be.cms.gluuoo.rumex.com.lk/',

  appUserRoles: {
    admin: "1",
    content_manager: "2",
    ads_manager: "3",
  },

  keycloak: {
    issuer: 'https://marketplaceauth.dialog.lk/auth/',
    realm: 'MarketPlace',
    clientId: 'rumex-voting',
    secrect: '998e9daf-8dff-433a-8822-91b0086017e0'
  },


  /* ************************************************************* */
  /* This is testing login, Remove after implement the auth module */

  appUserLoginCredentials: {
    admin_email: 'test@test.lk',
    admin_password: '123',
    content_manager_email: 'cm@ads.lk',
    content_manager_password: 'cm@ads',
    ads_manager_email: 'am@ads.lk',
    ads_manager_password: 'am@ads',
  },

  appUserToken: {
    accessToken: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIwNzc2MTA3NDI4IiwidXNlcklkIjoiMSIsInJvbGUiOiJVU0VSIiwiaWF0IjoxNjQwNjk0MjczLCJleHAiOjE2NDU4NzgyNzN9.YleLN45s-kLYo5-K_rNH7qe90muMfbQvqCJwvM_t_Bk'
  },

  /* ************************************************************* */

  apiVersion: 'v1',

};
